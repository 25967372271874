<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title">
                    <div class="filter d-flex align-items-center position-relative fs-6 text-gray-600">
                        <div class="filter-item">
                            <span>KATEGORI</span>
                            <el-select v-model="filter.category_id" placeholder="Pilih Kategori" clearable @change="onFilter" @clear="onFilter">
                                <el-option v-for="(o, i) in listRawCategory" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div class="table-fixed-header" ref="tableRef">
                <Datatable 
                    :table-header="tableHeader" 
                    :table-data="tableData"
                    :loading="loadingTable"
                    :total="totalPage"
                    :rows-per-page="50"
                    :enable-items-per-page-dropdown="false"
                >
                    <template v-slot:cell-index="{ row: data }">
                        <span>{{ data.index }}</span>
                    </template>
                    <template v-slot:cell-category="{ row: data }">
                        {{ data.category }}
                    </template>
                    <template v-slot:cell-price="{ row: data }">
                        {{ formatIDR(data.price) }}
                    </template>
                    <template v-slot:cell-discount_price="{ row: data }">
                        {{ formatIDR(data.discount_price) }}
                    </template>
                    <template v-slot:cell-reseller_price="{ row: data }">
                        {{ formatIDR(data.reseller_price) }}
                    </template>
                    <template v-slot:cell-action="{ row: data }">
                        <div class="d-flex gs-1 jsutify-end">
                            <div class="col">
                                <button class="btn btn-secondary btn-sm me-1" title="Edit" @click="getRow(data)" >
                                    <i class="fas fa-edit"></i>
                                </button>
                            </div>
                        </div>
                    </template>
                </Datatable>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>
        <el-dialog v-model="modal" :title="'Edit Harga Item'" width="600px" :close-on-click-modal="true">
            <Form
                class="form w-100"
                :validation-schema="tempData"
                @submit="onUpdate()"
            >
                <input type="hidden" name="id" v-model="tempData.id" disabled/>
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Kategori</label>
                        <input v-bind="field" :value="tempData.category.toUpperCase()" class="form-control" disabled />
                    </div>
                     <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Harga Tag</label>
                        <Field v-model="tempData.price" type="text" name="price" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="price" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Harga Diskon / Retail</label>
                        <Field v-model="tempData.discount_price" type="text" name="discount_price" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="discount_price" />
                            </div>
                        </div>
                    </div> <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Harga Wholesale</label>
                        <Field v-model="tempData.reseller_price" type="text" name="reseller_price" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="reseller_price" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="submit"
                        ref="submitButton"  
                        class="btn btn-primary"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </Form>
        </el-dialog>

        <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
            <inner-image-zoom :src="viewImageSrc" />
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    components: { 
        Datatable,
        Field,
        Form,
        ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const tableRef = ref(null)
        const modal = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            tipe: null,
            search: null,
        })
        
        const tableHeader = ref([
            {
                name: "#",
                key: "index",
            },
            {
                name: "Kategori",
                key: "category",
            },
            {
                name: "Harga Tag",
                key: "price",
            },
            {
                name: "Harga Diskon",
                key: "discount_price",
            },
            {
                name: "Harga Wholesale",
                key: "reseller_price",
            },
            {
                name: "Aksi",
                key: "action",
            },
        ]);

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        const tempData = reactive(Yup.object().shape({
            id: null,
            category: null,
            price: Yup.string().required().label('Harga Tag').nullable(true),
            discount_price: Yup.string().required().label('Harga Diskon').nullable(true),
            reseller_price: Yup.string().required().label('Harga Wholesae').nullable(true),
        }))

        const listRawCategory = reactive([]);
        
        const getRow = async(data = null) => {
            try {
                await Object.keys(tempData).map(k => tempData[k] = null)
                await Object.assign(tempData, data);
             
                modal.value = true

            } catch(e) {
                console.log(e)
            }
        }

        const onFilter = async (type) => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                //return
            }

            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          if(Object.values(filter).every(o => o == null || o.length == 0)) {
            //return
          }

          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let category = filter.category_id ? '&category='+filter.category_id : '';
                let search = filter.search ? '&search='+filter.search : '';

                await ApiService.get("price/list?page=" + page + category + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    Object.assign(tableData, data.data.data)
                })
                .catch((response) => {
                    throw new Error(response)
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
            
            loadingTable.value = false
        }

        const onUpdate = async() => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {
                const formData = new FormData();

                await ApiService.setHeader();
                await ApiService.post('price/update/'+tempData.id, tempData)
                .then(async({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000,
                    })

                    loading.close()
                    modal.value = false

                    await getData()
                })
                .catch((response) => {
                    throw new Error(response)
                });
                

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = data.logo
            viewImageVisible.value = true
            viewImageSrc.value = img_url_logo.value+data.logo
        }

        const getRawCategory = async () => {
            try {
                listRawCategory.splice(0)

                await ApiService.setHeader();
                await ApiService.get("category")
                .then(({ data }) => {
                    Object.assign(listRawCategory, data)
                })
                .catch(({ response }) => {
                    console.log('Error getting list category');
                });
            } catch(e) {
                console.log(e.message)
            }
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Master Harga", ["Master Harga"]);
            getData()
            getRawCategory()
        })

        return {
            route, router,            
            formatIDR,
            loadingTable, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            modal, getRow,
            pagination, handlePageChange,
            onFilter, resetFilter, filter, filterActive,
            listRawCategory,
            tableHeader, tableData, totalRecordCount, tempData, onUpdate,
        }
    }
})
</script>